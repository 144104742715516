import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 12 12"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M4 3.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM6.5 7.625c.138 0 .273-.018.4-.053a.375.375 0 1 1 .2.724c-.193.052-.394.079-.6.079-.964 0-1.752-.604-1.854-1.375H4.25a.25.25 0 0 1 0-.5h.375V6H4.25a.25.25 0 0 1 0-.5h.396c.103-.771.89-1.375 1.854-1.375.205 0 .407.027.6.08a.376.376 0 0 1-.2.723 1.515 1.515 0 0 0-.4-.053c-.529 0-.985.273-1.098.625H6.75a.25.25 0 0 1 0 .5H5.375v.5H6.75a.25.25 0 0 1 0 .5H5.402c.112.352.569.625 1.098.625Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "m10.705 5.79-4.5-4.5A.994.994 0 0 0 5.5 1H2c-.55 0-1 .45-1 1v3.5c0 .275.11.525.295.71l4.5 4.5a.994.994 0 0 0 1.41-.005l3.5-3.5A.978.978 0 0 0 11 6.5c0-.275-.115-.53-.295-.71ZM6.5 10.005 2 5.5V2h3.5v-.005l4.5 4.5-3.5 3.51Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }